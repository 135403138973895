<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="breadcrumb-inner text-center pt--100">
              <h2 class="heading-title theme-gradient">
                {{ selectedProject.name }}
              </h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="inner">
                <h2 class="title-line-height">{{ selectedProject.name }}</h2>
                <p class="subtitle">
                  {{ selectedProject.shortDesc }}
                </p>
                <p v-for="paragraph in selectedProject.fullDesc">
                  {{ paragraph }}
                </p>
                <div class="portfolio-view-list d-flex flex-wrap">
                  <div class="port-view">
                    <span>Project Type</span>
                    <h4>{{ selectedProject.projectType }}</h4>
                  </div>
                </div>
                <div
                  v-if="selectedProject.url"
                  class="portfolio-view-list d-flex flex-wrap mt--20"
                >
                  <div class="port-view">
                    <a
                      type="button"
                      class="rn-button-style--2 btn_solid"
                      :href="selectedProject.url"
                      target="_blank"
                    >
                      Visit Website
                    </a>
                  </div>
                </div>
              </div>
              <div class="portfolio-thumb-inner mt--60">
                <div
                  class="thumb thumbnail mt--10"
                  v-for="index in selectedProject.numOfImages"
                >
                  <img
                    :src="
                      require(`../../../assets/images/portfolio/${selectedProject.key}/${index}.jpg`)
                    "
                    v-bind:alt="index"
                  />
                </div>
              </div>
              <div class="portfolio-thumb-inner mt--60">
                <div
                  class="thumb thumbnail mt--10"
                  v-for="videoSrc in selectedProject.videos"
                >
                  <iframe
                    width="100%"
                    height="650px"
                    :src="videoSrc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import data from "../../../data/portfolio.json";

export default {
  components: {
    Header,
    Footer
  },
  props: ["projectName"],
  data() {
    return {
      projects: data.projects
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "Portfolio",
          to: "/portfolio",
          disabled: false
        },
        {
          text: this.selectedProject.name,
          to: "",
          disabled: true
        }
      ];
    },
    selectedProject() {
      return this.projects.find((x) => x.key == this.projectName);
    }
  }
};
</script>
<style scoped>
.title-line-height {
  line-height: 60px !important;
}
</style>
